import AWS from "aws-sdk";
export const BASE_URL = "https://api.datacse.com";
//export const BASE_URL = 'http://127.0.0.1:5000';
// export const BASE_URL = "https://3f61-39-62-56-179.ap.ngrok.io";

export const S3_BUCKET ="casedata-files-storage";
const REGION = "us-east-1";

AWS.config.update({
  accessKeyId: "AKIARQIEZQGEONVXUC66",
  secretAccessKey: "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",
});

export const myBucket = new AWS.S3({
  params: {
    Bucket: S3_BUCKET,
  },
  region: REGION,
});
