import React from "react";
import Stepper from "../stepper/Stepper";
import style from "./admin.module.css";

function Admin({ editingData }) {
  //const [editingData, setEditingData] = React.useState(false);
  //console.log(editingData);
  return (
    <div className={style.main}>
      <Stepper editingData={editingData} />
    </div>
  );
}

export default Admin;
