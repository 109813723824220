import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import style from "./step1.module.css";
import * as Yup from "yup";
//import courtHouseList from "../CourtHouseList";
import { BASE_URL } from "../../../config";
function Step3({ initialData, onNextClick, setFileAdd, fileAdd }) {
  const [file, setFile] = useState({});
  const [courtHouses, setCourtHouses] = useState([]);
  const [courtState, setCourtState] = useState('');
  const [courtCounty, setCourtCounty] = useState('');

  // s3 config
  const onUpload = async (e) => {};
  const dateStr = "2022-07-21T09:35:31.820Z";
  const date1 = new Date(dateStr);

  const result = new Date(date1.toISOString().slice(0, -1));

  const [fileHover, setFileHover] = useState(false);
  const [active, setActive] = useState(false);
  const baniList = [
    {
      beneficiaryName: "beneficiaryName",
      relation: "",
      beneficiaryAddress: "beneficiaryAddress",
      beneficiaryCity: "dasbeneficiaryCityd",
      beneficiaryState: "beneficiaryState",
      beneficiaryZip: "new",
      beneficiaryCountry: "beneficiaryCountry",
    },
  ];
  const data = {
    case_number: 181,
    decedent_name: "decedent_name",
    decedent_address: "decedent_address",
    decedent_city: "decedent_city",
    decedent_state: "decedent_state",
    decedent_zip: "decedent_zip",
    date_filed: "2021-11-04 08:30:00",
    date_of_death: "2021-11-04 08:30:00",
    petitioner: "petitioner",
    net_property_value: 65543163,
    attorney_name: "attorney_name",
    attorney_address: "attorney_address",
    attorney_city: "attorney_city",
    attorney_state: "attorney_state",
    attorney_zip: "attorney_zip",
    personal_property: 632,
    interstate: false,
    court_house: "Pasadena Courthouse",
    beneficiaryList: baniList,
    pdfFile: "link",
  };
  var date = new Date("2021-12-30 10:30:00");
  
  useEffect(() => {
  (async () => {
    try {
      const response = await fetch(`${BASE_URL}/courtHouses`);
      const data = await response.json();
      setCourtHouses(data);
    } catch (error) {
      console.error("Error fetching court house data:", error);
    }
  })();
}, []);

  const formik = useFormik({
    initialValues: {
      propertyValue: "",
      court_id: "",
      pdf: "",
      personalProperty: "",
      interstate: false, 
    },
    validationSchema: Yup.object({
      propertyValue: Yup.string().required("Property value is required"),
      court_id: Yup.string().required("Court house name is required"),
      personalProperty: Yup.string().required("Personal Property is required"),
      pdf: Yup.string().required("Pdf is required"),
    }),
  });

  const handleCourtHouseChange = (selectedCourtId) => {
    // Find the selected court house data based on the selectedCourtId
    const selectedCourtHouse = courtHouses.find(courtHouse => courtHouse.court_id == selectedCourtId);
   
    // Update the courtState and courtCounty values
    if (selectedCourtHouse) {
      setCourtState(selectedCourtHouse.court_state);
      setCourtCounty(selectedCourtHouse.court_county);
    } else {
      // Handle the case when the selected court house is not found
      setCourtState('');
      setCourtCounty('');
    }
  };

  useEffect(() => {
    if (initialData) {
      handleCourtHouseChange(initialData['court_id']); 
      setActive(initialData['interstate']);
      formik.setValues({
        propertyValue: initialData['net_property_value'],
        court_id: initialData['court_id'],
        pdf: initialData['case_file'],
        personalProperty: initialData['personal_property'],
        interstate: initialData['interstate'],
      });
    }
  }, [initialData, courtHouses]);

  const { submitForm, values, errors } = formik;
  var filename = values.pdf.replace(/^.*[\\\/]/, "") || "Select a file";
  filename = filename !== "Select a file" && fileHover ? "Replace " : filename;
  useEffect(() => {
    values.interstate = active;
    onNextClick({ values: values, submitForm });
  }, [values, active]);
  const onRemove = () => {
    formik.setFieldValue("pdf");
  };
  // upload file to s3
  
  return (
    <div>
      <div>
        {/* <input
          type={"file"}
          onChange={(e) => setFile(e.target.files[0])}
          accept="application/pdf"
        /> */}
        <form className={style.form} onSubmit={formik.handleSubmit}>
          <div className={style.fieldCont}>
            <label className={style.label}>Property Value</label>
            <input
              className={style.input}
              placeholder="Property Value"
              id="propertyValue"
              name="propertyValue"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.propertyValue}
            />
            <div
              style={{
                opacity:
                  formik.touched.propertyValue && formik.errors.propertyValue
                    ? "1"
                    : 0,
              }}
              className={style.errMessage}
            >
              {formik.errors.propertyValue}
            </div>
          </div>
          <div className={style.fieldCont}>
            <label className={style.label}>Court House Name</label>
            <select
              placeholder="Court House Name"
              className={style.input}
              id="court_id"
              name="court_id" 
              type="text"
              onChange={(e) => {  
                formik.handleChange(e); // This will update formik state
                handleCourtHouseChange(e.target.value); // Call the custom handler with the selected value
              }}
              
              //onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.court_id}
            >
              <option value="">-</option>
              {courtHouses.map((courtHouse) => (
                <option key={courtHouse.court_id} value={courtHouse.court_id}>
                 {courtHouse.court_name}
                </option>
              ))}
            </select>

            <div
              style={{
                opacity:
                  formik.touched.courtHouseName && formik.errors.courtHouseName
                    ? "1"
                    : 0,
              }}
              className={style.errMessage}
            >
              {formik.errors.courtHouseName}
            </div>
          </div>
          {/* Display Court State */}
          <div className={style.fieldCont}>
            <label className={style.label}>Court State</label>
            <input
              className={style.input}
              placeholder="Court State"
              id="court_state"
              name="court_state"
              type="text"
              readOnly
              value={courtState}
            />
          </div>

          {/* Display Court County */}
          <div className={style.fieldCont}>
            <label className={style.label}>Court County</label>
            <input
              className={style.input}
              placeholder="Court County"
              id="court_county"
              name="court_county"
              type="text"
              readOnly
              value={courtCounty}
            />
          </div>

          <div className={style.fieldCont}>
            <label className={style.label}>Upload Pdf</label>
            <label
              htmlFor="pdf"
              onMouseEnter={() => setFileHover(true)}
              onMouseLeave={() => setFileHover(false)}
              className={style.fileLabel}
            >
              {fileAdd?.name || "Select File"}
            </label>
            <input
              style={{
                display: "none",
              }}
              className={style.input}
              id="pdf"
              name="pdf"
              accept="application/pdf"
              type="file"
              onChange={(e) => {
                setFileAdd(e.target.files[0]);
              }}
              // onBlur={formik.handleBlur}
              // value={file}
            />
            <div
              style={{
                opacity: formik.touched.pdf && formik.errors.pdf ? "1" : 0,
              }}
              className={style.errMessage}
            >
              {formik.errors.pdf}
            </div>
          </div>
          <div className={style.fieldCont}>
            <label className={style.label}>Personal Property</label>
            <input
              placeholder="Personal Property"
              className={style.input}
              id="personalProperty"
              name="personalProperty"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.personalProperty}
            />
            <div
              style={{
                opacity:
                  formik.touched.personalProperty &&
                  formik.errors.personalProperty
                    ? "1"
                    : 0,
              }}
              className={style.errMessage}
            >
              {formik.errors.personalProperty}
            </div>
          </div>
          <div className={style.fieldCont}>
            <label className={style.label}>Interstate Yes or No?</label>
            <div
              onClick={() => setActive(!active)}
              className={active ? style.toggleActive : style.toggle}
            >
              <div className={active ? style.circleActive : style.circle}></div>
               {/* Hidden input element to include this in form data */}
              <input type="hidden" id="interstate" name="interstate" value={active} />
            </div>
          </div>
        </form>
      </div>
      <div className={style.btnContainer}>
        {/* <button onClick={onUpload}>Upload</button> */}
        {/* <button onClick={onClick}>submit</button> */}
      </div>
    </div>
  );
}

export default Step3;
