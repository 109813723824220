import React, { useState, useEffect } from "react";
import style from "./step1.module.css";
import { Calendar } from "react-date-range";
import { debug } from "util";

function Step2({ initialData, onNextClick, step }) {

  const [date1, setDate1] = useState({ dateFilled: new Date().toJSON().split("T")[0] });
  const [date2, setDate2] = useState({ dateOfDeath: new Date().toJSON().split("T")[0] });

  const year = new Date(date1?.dateFilled + "T00:00:00").getFullYear();
  const month = new Date(date1?.dateFilled + "T00:00:00").getMonth();
  const day = new Date(date1?.dateFilled + "T00:00:00").getDate();
  const filledDate = new Date(year, month, day, 0, 0, 0, 0);
  //filledDate.setHours(0,0,0,0);

  const year1 = new Date(date2?.dateOfDeath + "T00:00:00").getFullYear();
  const month1 = new Date(date2?.dateOfDeath + "T00:00:00").getMonth();
  const day1 = new Date(date2?.dateOfDeath + "T00:00:00").getDate();
  const deathDate = new Date(year1, month1, day1, 0, 0, 0, 0);
  //deathDate.setHours(0,0,0,0);
  //const values = { filledDate, deathDate };
  
  //console.log(initialData);

  useEffect(() => {
    if (initialData) {
      setDate1({ dateFilled: new Date(initialData['date_filed']).toISOString().slice(0, 10) });
      setDate2({ dateOfDeath: new Date(initialData['date_of_death']).toISOString().slice(0, 10) });
    };
  }, [initialData]);

  useEffect(() => { // calling this to set filledDate and deathDate in handleSubmit after initialData is set in date1 and date2
    const year = new Date(date1?.dateFilled + "T00:00:00").getFullYear();
    const month = new Date(date1?.dateFilled + "T00:00:00").getMonth();
    const day = new Date(date1?.dateFilled + "T00:00:00").getDate();
    const filledDate = new Date(year, month, day, 0, 0, 0, 0);

    const year1 = new Date(date2?.dateOfDeath + "T00:00:00").getFullYear();
    const month1 = new Date(date2?.dateOfDeath + "T00:00:00").getMonth();
    const day1 = new Date(date2?.dateOfDeath + "T00:00:00").getDate();
    const deathDate = new Date(year1, month1, day1, 0, 0, 0, 0);

    handleSubmit(filledDate, deathDate);
  }, [date1, date2]);

  const handleSubmit = (newFilledDate, newDeathDate) => {
    onNextClick({
      values: {
        filledDate: newFilledDate || filledDate || new Date(),
        deathDate: newDeathDate || deathDate || new Date(),
      },
    });
  };

  const onChange1 = (date) => {
    //const options = { timeZone : 'America/Los_Angeles'};
    setDate1({ dateFilled: JSON.stringify(date).slice(1, 11) });
    const dateA = { dateFilled: JSON.stringify(date).slice(1, 11) };
    const year = new Date(dateA?.dateFilled + "T00:00:00").getFullYear();
    const month = new Date(dateA?.dateFilled + "T00:00:00").getMonth();
    const day = new Date(dateA?.dateFilled + "T00:00:00").getDate();
    const newFilledDate = new Date(year, month, day, 0, 0, 0, 0) ;
    //newFilledDate.setHours(0,0,0,0);
    handleSubmit(newFilledDate, deathDate);
  };
  

  const onChange2 = (date) => {
    setDate2({ dateOfDeath: JSON.stringify(date).slice(1, 11) });
    const dateB = { dateOfDeath: JSON.stringify(date).slice(1, 11) };
    const year = new Date(dateB?.dateOfDeath + "T00:00:00").getFullYear();
    const month = new Date(dateB?.dateOfDeath + "T00:00:00").getMonth();
    const day = new Date(dateB?.dateOfDeath + "T00:00:00").getDate();
    const newDeathDate = new Date(year, month, day, 0, 0, 0, 0);
    //newDeathDate.setHours(0,0,0,0);
    handleSubmit(filledDate, newDeathDate);
  };

  return (
    <div className={style.step2Main} onSubmit={handleSubmit}>
      <div className={style.calendarsCont}>
        <div className={style.calenderLeft}>
          <h4 className={style.dateHeading}>
            <p> Date Filed</p>
            {`${year}-${month + 1}-${day}`}
          </h4>
          <Calendar date={filledDate} dateDisplayFormat='yyyy-MM-dd' onChange={onChange1} />
        </div>
        <div className={style.calenderRight}>
          <h4 className={style.dateHeading}>
            <p> Date of Death</p>
            {`${year1}-${month1 + 1}-${day1}`}
          </h4>
          <Calendar date={deathDate} dateDisplayFormat='yyyy-MM-dd'  onChange={onChange2} />
        </div>
      </div>
    </div>
  );
}

export default Step2;
